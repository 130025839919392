import BaseService, { CompanyBaseService } from './BaseService'

export const CompanyApiService = {
    fetchData(param) {
        return new Promise(async(resolve, reject) => {
            let re = await CompanyBaseService.post('companyLogin')
            // console.log("re ==========================>", re);
            // CompanyBaseService(param).then(response => {
            //     console.log("response ::::::::::::", response);
            //     resolve(response)
            // }).catch(errors => {
            //     reject(errors)
            // })
        })
    }
}

const ApiService = {
    fetchData(param) {
        return new Promise((resolve, reject) => {
            BaseService(param).then(response => {
                resolve(response)
            }).catch(errors => {
                reject(errors)
            })
        })
    }
}

export default ApiService