import Axios from "axios";
import { Notification, toast } from "components/ui";
import socketIO from "socket.io-client";


// const BASE_ED_URL = "https://www.ed2100.com/api/";
const BASE_RD_LOCAL_URL = "https://stage-partner.ed2100.com/api2/";
// const BASE_RD_LOCAL_URL = 'http://localhost:8089/'


// const BASE_RD_LOCAL_URL = "http://192.168.0.27:8089/"
// const BASE_RD_LOCAL_URL = "http://192.168.1.13:8089/"

// const BASE_RD_LOCAL_URL = "http://192.168.0.70:8089/"
// const BASE_RD_LOCAL_URL = "http://192.168.0.42:8089/"
 
// const socket = socketIO.connect("http://192.168.0.70:8089");
export function generateOtp(value) {
	let body = value ;


	return new Promise((res, rej) => {
		Axios.post(BASE_RD_LOCAL_URL + `school/generate-otp`, body )
			.then((response) => {
				res(response.data);
			})
			.catch((err) => rej(err));
	});
}

export function verifyEmailOtp(value) {
	let body = value ;


	return new Promise((res, rej) => {
		Axios.post(BASE_RD_LOCAL_URL + `user/verify-email-otp`, body )
			.then((response) => {
				res(response.data);
			})
			.catch((err) => rej(err));
	});
}
export function login(email, password) {
  let body = {
    email: email,
    password: password,
  };
  let config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  return new Promise((res, rej) => {
    Axios.post(BASE_RD_LOCAL_URL + "user/login", body, config)
      .then((response) => {
        res(response.data);
      })
      .catch((err) => {
        rej(err);
        toast.push(
          <Notification
            title={`This Email is not Registered!`}
            type="danger"
          />,
          {
            placement: "top-end",
          }
        );
      });
  });
}

export function addUpdateProfileBasedOnRole(values, token) {

  let address = {
    line1: values.line1,
    state: values.state,
    country: values.country,
  }

  let body = {
    firstName : values.firstName,
    lastName: values.lastName,
    email: values.email,
    dob: values.dob,
    contactNumber : values.contactNumber, 
    mobileNumber: values.mobileNumber,
    address: address,
    subjectExpertise: values.subjectExpertise,
    highestQualification: values.highestQualification,
    dateOfJoining: values.dateOfJoining,
    profileImage: values.profileImage
  }

  let config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  };
  return new Promise((res, rej) => {
    Axios.post(
      BASE_RD_LOCAL_URL + "user/add-update-profile-based-on-role",
      body,
      config
    )
      .then((response) => {
        // console.log("response from cvreate profile::::::::::", response);
        res(response.data);
      })
      .catch((err) => rej(err));
  });
}

export function forget(values) {
  // let config = {
  //   headers: {
  //     "Content-Type": "application/json",

  //   },
  // };
  return new Promise((res, rej) => {
    Axios.post(BASE_RD_LOCAL_URL + "user/forget", values)
      .then((response) => {
        // console.log("response from forget::::::::::", response);
        res(response.data);
      })
      .catch((err) => rej(err));
  });
}

export function getUserProfileBasedOnRole(tk) {
  let config = {
    headers: {
      Authorization: tk,
    },
  };
  return new Promise((_res, rej) => {
    Axios.get(BASE_RD_LOCAL_URL + `user/get-user-profile-based-on-role`, config)
      .then((res) => {
        _res(res.data);
      })
      .catch((err) => rej(err));
  });
}

export function retrieveSubjectById(data) {
  // console.log("data::::::::::>>>>>",data)
  let config = {
    headers: {},
  };
  return new Promise((_res, rej) => {
    Axios.get(BASE_RD_LOCAL_URL + `user/retrieveSubjectById/${data}`, config)
      .then((res) => {
        _res(res.data);
      })
      .catch((err) => rej(err));
  });
}

export function generateLessonPlan(prompt,token) {
  let config = {
    headers: {
      Authorization: token,
    },
  };
  console.log("promptprompt",prompt,token)
  return new Promise((_res, rej) => {
   Axios.post(`${BASE_RD_LOCAL_URL}teacher/create-lesson-plan-ai`, { prompt },config)
   .then((res) => {
        _res(res.data);
      })
      .catch((err) => rej(err));
  })

};

export function createQuizAi(prompt,token) {
  let config = {
    headers: {
      Authorization: token,
    },
  };
  // console.log("promptprompt",prompt,token)
  return new Promise((_res, rej) => {
   Axios.post(`${BASE_RD_LOCAL_URL}user/create-quiz-ai`,  prompt ,config)
   .then((res) => {
        _res(res.data);
      })
      .catch((err) => rej(err));
  })

};

export function generateLessonPlannerHumanInterface(values,token) {

  let body = values
  let config = {
    headers: {
      Authorization: token,
    },
  };
  // console.log("promptprompt",prompt,token)
  return new Promise((_res, rej) => {
   Axios.post(`${BASE_RD_LOCAL_URL}teacher/create-lesson-plan`, body ,config)
   .then((res) => {
        _res(res.data);
      })
      .catch((err) => rej(err));
  })

};

export function getAllLessonPlan(token , search , subject , classId) {
  let config = {
    headers: {
      Authorization: token,
    },
  };
 
  return new Promise((_res, rej) => {
   Axios.get(`${BASE_RD_LOCAL_URL}teacher/get-all-lesson-plan-ai?planTitle=${search}&subject=${subject}&classId=${classId}`,config)
   .then((res) => {
        _res(res.data);
      })
      .catch((err) => rej(err));
  })

};

export function getLessonPlannerById(token,lessonPlannerId,createdBy) {
  let config = {
    headers: {
      Authorization: token,
    },
  };
  
  return new Promise((_res, rej) => {
   Axios.get(`${BASE_RD_LOCAL_URL}teacher/get-lesson-plan-ai-by-id/${lessonPlannerId}?createdBy=${createdBy}`,config)
   .then((res) => {
        _res(res.data);
      })
      .catch((err) => rej(err));
  })

};

// export function retrieveChapterById(data) {
//   // console.log("data::::::::::>>>>>",data)
//   let config = {
//     headers: {},
//   };
//   return new Promise((_res, rej) => {
//     Axios.get(BASE_RD_LOCAL_URL + `user/retrieveChapterById/${data}`, config)
//       .then((res) => {
//         _res(res.data);
//       })
//       .catch((err) => rej(err));
//   });
// }

export function retrieveChapterById(data) {
  // console.log("data::::::::::>>>>>",data)
  let config = {
    headers: {},
  };
  return new Promise((_res, rej) => {
    Axios.get(BASE_RD_LOCAL_URL + `user/retrieve-chapter-by-id/${data}`, config)
      .then((res) => {
        _res(res.data);
      })
      .catch((err) => rej(err));
  });
}

// export function retrieveContentById(content) {
//   // console.log("data::::::::::>>>>>",data)
//   let config = {
//     headers: {},
//   };
//   return new Promise((_res, rej) => {
//     Axios.get(
//       BASE_RD_LOCAL_URL + `user/retrieve-topic-by-id/${content}`,
//       config
//     )
//       .then((res) => {
//         _res(res.data);
//       })
//       .catch((err) => rej(err));
//   });
// }

export function getAllAssignedCoursesListForUsers(token, searchName) {
  let config = {
    headers: {
      Authorization: token,
    },
  };
  return new Promise((_res, rej) => {
    Axios.get(
      BASE_RD_LOCAL_URL +
      `teacher/get-teacher-assigned-courses?courseName=${searchName}`,
      config
    )
      .then((res) => {
        _res(res.data);
      })
      .catch((err) => rej(err));
  });
}

export function getdashDataForTeacher(token) {
  let config = {
    headers: {
      Authorization: token,
    },
  };
  return new Promise((_res, rej) => {
    Axios.get(
      BASE_RD_LOCAL_URL +
      `teacher/get-teacher-dashboard-stats`,
      config
    )
      .then((res) => {
        _res(res.data);
      })
      .catch((err) => rej(err));
  });
}

export function getClassAnyltics(classId) {
  return new Promise((_res, rej) => {
    Axios.get(
      BASE_RD_LOCAL_URL +
      `teacher/teacher-class-anlytics/${classId}`,)
      .then((res) => {
        _res(res.data);
      })
      .catch((err) => rej(err));
  });
}

export function getMyStudentReprtAnylytics(studentUserId,classId) {
  return new Promise((_res, rej) => {
    Axios.get(
      BASE_RD_LOCAL_URL +
      `student/get-student-analytics/${studentUserId}/${classId}`,)
      .then((res) => {
        _res(res.data);
      })
      .catch((err) => rej(err));
  });
}

export function getdashDataForTeacherAssignments(token) {
  let config = {
    headers: {
      Authorization: token,
    },
  };
  return new Promise((_res, rej) => {
    Axios.get(
      BASE_RD_LOCAL_URL +
      `teacher/teacher-dashboard-assignment-stats`,
      config
    )
      .then((res) => {
        _res(res.data);
      })
      .catch((err) => rej(err));
  });
}

export function getAllCoursesListForUsers(user_id) {
  return new Promise((_res, rej) => {
    Axios.get(BASE_RD_LOCAL_URL + `user/getAllCoursesListForUsers`)
      .then((res) => {
        _res(res.data);
      })
      .catch((err) => rej(err));
  });
}

export function getAllStudentsOfSchoolByGradeSection(
  token,
  grade,
  classId,
  section
  
) {
  let config = {
    headers: {
      Authorization: token,
    },
  };

  // console.log("token , grade " , token , grade , classId , subjectName);
  return new Promise((_res, rej) => {
    Axios.get(
      BASE_RD_LOCAL_URL +
        `school/get-all-students-of-school-by-grade-section/${grade}/${classId}/${section}`,
      config
    )
      .then((res) => {
        _res(res.data);
      })
      .catch((err) => rej(err));
  });
}

export function retrieveCourseDetailWithContent(courseId, tk) {
  let config = {
    headers: {
      Authorization: tk,
    },
  };
  return new Promise((_res, rej) => {
    Axios.get(
      BASE_RD_LOCAL_URL +
      `user/retrieve-course-detail-with-content/${courseId}`,
      config
    )
      .then((res) => {
        _res(res.data);
      })
      .catch((err) => rej(err));
  });
}
export function retrieveCourseById(courseId) {
  let config = {
    // headers: {
    //   Authorization: tk,
    // },
  };
  return new Promise((_res, rej) => {
    Axios.get(BASE_RD_LOCAL_URL + `user/retrieve-course-by-id/${courseId}`)

      .then((res) => {
        _res(res.data);
      })
      .catch((err) => rej(err));
  });
}

export function getTeacherStudent(tk) {
  // console.log("tk",tk)
  let config = {
    headers: {
      Authorization: tk,
    },
  };

  return new Promise((_res, rej) => {
    Axios.get(BASE_RD_LOCAL_URL + `teacher/get-teacher-students`, config)
      .then((res) => {
        console.log("data from api", res);
        _res(res.data);
      })
      .catch((err) => rej(err));
  });
}

export function getTeacherStudentList(tk) {
  // console.log("tk",tk)
  let config = {
    headers: {
      Authorization: tk,
    },
  };
  return new Promise((_res, rej) => {
    Axios.get(BASE_RD_LOCAL_URL + `teacher/get-teacher-students-list`, config)
      .then((res) => {
        // console.log("data from api",res)
        _res(res.data);
      })
      .catch((err) => rej(err));
  });
}
// export function searchApi(searchData,token) {

//   let config = {
//     headers: {
//       Authorization: token
//     }
//   };

//   // console.log("tk",tk)

//   return new Promise((_res, rej) => {
//     Axios.get(BASE_RD_LOCAL_URL +
//       `user/search-api?searchText=${searchData.searchText ? searchData.searchText:""}&grade=${""}&subject=${searchData.subject ? searchData.subject:""}&brand=${searchData.brand ? searchData.brand:""}&contentType=${""}&language=${searchData.language ?searchData.language  :"" }`,config)
//     // Axios.get(BASE_RD_LOCAL_URL + `user/search-api?searchText=${data}`)
//       .then((res) => {
//         // console.log("data from api",res)
//         _res(res.data);
//       })
//       .catch((err) => {
//         rej(err)
//         console.log("rej(err)",err.response.data.Message)
//         // toast.push(
//         //       <Notification title={err.response.data.Message} type="danger" />
//         //       , {
//         //         placement: 'top-center'
//         //       })
//       });
//   });
// }

export function searchApi(searchText, filterString, bucketString, token) {
  let config = {
    headers: {
      Authorization: token,
    },
  };

  // console.log("tk",tk)

  return new Promise((_res, rej) => {
    Axios.get(
      BASE_RD_LOCAL_URL +
      `user/search-api?searchText=${searchText ? searchText : ""
      }&filters=${filterString}&buckets=${bucketString}&use_students_flag=False&count=3`,
      config
    )
      // Axios.get(BASE_RD_LOCAL_URL + `user/search-api?searchText=${data}`)
      .then((res) => {
        // console.log("data from api",res)
        _res(res.data);
      })
      .catch((err) => {
        rej(err);
        console.log("rej(err)", err.response.data.Message);
        // toast.push(
        //       <Notification title={err.response.data.Message} type="danger" />
        //       , {
        //         placement: 'top-center'
        //       })
      });
  });
}

export function createSchedule(values, tk) {
  console.log("values", tk, values);
  const gradeArray = [];
  // if(values.tags && values.tags.length>0){
  values.grade.map((gradeData) => {
    gradeArray.push(gradeData.label);
  });
  // }

  let body = {
    title: values.title,
    startTime: values.startTime,
    grade: gradeArray,
    location: values.location,
    pep: values.pep,
  };
  let config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: tk,
    },
  };
  return new Promise((res, rej) => {
    Axios.post(BASE_RD_LOCAL_URL + "scheduler/create-schedule", body, config)
      .then((response) => {
        // console.log("response from cvreate profile::::::::::", response);
        res(response.data);
      })
      .catch((err) => rej(err));
  });
}

export function getSchedule(tk) {
  // console.log("tk",tk)
  let config = {
    headers: {
      Authorization: tk,
    },
  };
  return new Promise((_res, rej) => {
    Axios.get(BASE_RD_LOCAL_URL + `scheduler/retrieve-schedule`, config)
      .then((res) => {
        // console.log("data from api",res)
        _res(res.data);
      })
      .catch((err) => rej(err));
  });
}

export function getExploreData(tk, user_id) {
  let config = {
    headers: {
      Authorization: tk,
    },
  };
  return new Promise((_res, rej) => {
    Axios.get(BASE_RD_LOCAL_URL + `user/get-explore-data`)
      .then((res) => {
        _res(res.data);
      })
      .catch((err) => rej(err));
  });
}

export function getWishlistData(tk) {
  let config = {
    headers: {
      Authorization: tk,
    },
  };
  return new Promise((_res, rej) => {
    Axios.get(BASE_RD_LOCAL_URL + `user/retrieve-wishlist`, config)
      .then((res) => {
        _res(res.data);
      })
      .catch((err) => rej(err));
  });
}

export function createWishList(tk, courseId) {
  // console.log("courseid", courseId);

  let body = {
    courseId: [courseId],
  };

  let config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: tk,
    },
  };
  return new Promise((res, rej) => {
    Axios.post(BASE_RD_LOCAL_URL + "user/create-wishlist", body, config)
      .then((response) => {
        // console.log("response from cvreate profile::::::::::", response);
        res(response.data);
      })
      .catch((err) => rej(err));
  });
}

export function markTopicAsCompleted(topicDataId) {
  

  let body = {
    lessonPlanerTopicId : topicDataId
  };

  
  return new Promise((res, rej) => {
    Axios.post(BASE_RD_LOCAL_URL + "teacher/mark-topic-as-completed", body)
      .then((response) => {
        // console.log("response from cvreate profile::::::::::", response);
        res(response.data);
      })
      .catch((err) => rej(err));
  });
}

export function createRequest(tk, courseId) {
  console.log("courseid from live api", courseId);

  let body = {
    validity: "15min",
    courseId: courseId,
  };

  let config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: tk,
    },
  };
  return new Promise((res, rej) => {
    Axios.post(BASE_RD_LOCAL_URL + "user/create-request", body, config)
      .then((response) => {
        // console.log("response from cvreate profile::::::::::", response);
        res(response.data);
      })
      .catch((err) => rej(err));
  });
}

export function createTeacherClass(values, tk) {
  // let subjectArray = [];
  // values.subject.map((item) => {
  //   subjectArray.push(item.value);
  // });
  // let body = {
  //   grade: values.grade,
  //   section: values.section,
  //   subjectDataId: [values.subject],
  // };

  let body = values;

  let config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: tk,
    },
  };
  return new Promise((res, rej) => {
    Axios.post(BASE_RD_LOCAL_URL + "teacher/create-teacher-class", body, config)
      .then((response) => {
        // console.log("response from cvreate profile::::::::::", response);
        res(response.data);
      })
      .catch((err) => rej(err));
  });
}

export function getAllTeacherClasses(tk, lessonId , quizId) {
  let config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: tk,
    },
  };
  return new Promise((_res, rej) => {
    Axios.get(BASE_RD_LOCAL_URL + `teacher/get-all-teacher-classes?lessonPlannerId=${lessonId}&quizId=${quizId}`, config)
      .then((res) => {
        _res(res.data);
      })
      .catch((err) => rej(err));
  });
}

export function psychometricData(category) {
  return new Promise((_res, rej) => {
    Axios.get(
      BASE_RD_LOCAL_URL +
      `vendor/retrieve-all-filtered-psychometric-data-by-category?category=${category}`
    )
      .then((res) => {
        _res(res.data);
      })
      .catch((err) => rej(err));
  });
}
// export function exploreDetailWithName() {
//   return new Promise((_res, rej) => {
//     Axios.get(BASE_RD_LOCAL_URL + `user/explore-detail-with-name`)
//       .then((res) => {
//         _res(res.data);
//       })
//       .catch((err) => rej(err));
//   });
// }

export function exploreDetailWithName() {
  return new Promise((_res, rej) => {
    Axios.get(BASE_RD_LOCAL_URL + `user/explore-detail-with-name`)
      .then((res) => {
        _res(res.data);
      })
      .catch((err) => rej(err));
  });
}

export function retrieveGradeDetail() {
  return new Promise((_res, rej) => {
    Axios.get(BASE_RD_LOCAL_URL + `user/retrieve-grade-details`)
      .then((res) => {
        _res(res.data);
      })
      .catch((err) => rej(err));
  });
}

export function getAllSubjectForTeacher() {
  return new Promise((_res, rej) => {
    Axios.get(BASE_RD_LOCAL_URL + `teacher/get-all-subject-for-teacher`)
      .then((res) => {
        _res(res.data);
      })
      .catch((err) => rej(err));
  });
}

export function retreiveAllPartner() {
  return new Promise((_res, rej) => {
    Axios.get(BASE_RD_LOCAL_URL + `vendor/retreive-all-partner`)
      .then((res) => {
        _res(res.data);
      })
      .catch((err) => rej(err));
  });
}

export function retrieveLanguageDetail() {
  return new Promise((_res, rej) => {
    Axios.get(BASE_RD_LOCAL_URL + `user/retrieve-languages-details`)
      .then((res) => {
        _res(res.data);
      })
      .catch((err) => rej(err));
  });
}

export function exploreDetails() {
  return new Promise((_res, rej) => {
    Axios.get(BASE_RD_LOCAL_URL + `user/explore-details`)
      .then((res) => {
        _res(res.data);
      })
      .catch((err) => rej(err));
  });
}

export function addToShelf(courseId, token) {
  let body = {
    courseId: courseId,
  };
  let config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  };
  return new Promise((res, rej) => {
    Axios.post(BASE_RD_LOCAL_URL + "user/create-shelf", body, config)
      .then((response) => {
       
        res(response.data);
      })
      .catch((err) => {
        rej(err);
     
        toast.push(
          <Notification title={err.response.data.Message} type="danger" />,
          {
            placement: "top-center",
          }
        );
      });
  });
}

export function retrieveFilteredCourses(courseData) {
  return new Promise((_res, rej) => {
    Axios.get(
      BASE_RD_LOCAL_URL +
      `vendor/retrieve-filtered-courses?grade=${courseData && courseData.grade
      }&subject=${courseData && courseData.subject}`
    )
      .then((res) => {
        _res(res.data);
      })
      .catch((err) => rej(err));
  });
}

export function getAllCourseListBySubjectName(subName) {
  return new Promise((_res, rej) => {
    Axios.get(
      BASE_RD_LOCAL_URL +
      `user/get-all-course-list-by-subject-name?SubjectName=${subName}`
    )
      .then((res) => {
        _res(res.data);
      })
      .catch((err) => rej(err));
  });
}

export function retrieveChapters(subjectId) {
  return new Promise((_res, rej) => {
    Axios.get(BASE_RD_LOCAL_URL + `user/retrieve-chapter-by-id/${subjectId}`)
      .then((res) => {
        _res(res.data);
      })
      .catch((err) => rej(err));
  });
}

export function retrieveTopics(topicId, profileUserID) {
  return new Promise((_res, rej) => {
    Axios.get(
      BASE_RD_LOCAL_URL +
      `user/retrieve-topic-by-id/${topicId}?user_id=${profileUserID}`
    )
      .then((res) => {
        _res(res.data);
      })
      .catch((err) => rej(err));
  });
}

export function retrieveFilteredChapter(grades, subjects, courseId) {
  return new Promise((_res, rej) => {
    Axios.get(
      BASE_RD_LOCAL_URL +
      `vendor/retrieve-filtered-chapter/${courseId}?grade=${grades}&subject=${subjects}`
    )
      .then((res) => {
        _res(res.data);
      })
      .catch((err) => rej(err));
  });
}

export function playVideo(token, courseId) {
  let config = {
    headers: {
      Authorization: token,
    },
  };
  return new Promise((_res, rej) => {
    Axios.get(
      BASE_RD_LOCAL_URL +
      `user/retrieve-course-detail-with-content/${courseId}`,
      config
    )
      .then((res) => {
        _res(res.data);
      })
      .catch((err) => rej(err));
  });
}

export function psychometricSubject() {
  return new Promise((_res, rej) => {
    Axios.get(BASE_RD_LOCAL_URL + `user/get-pychometric-subject`)
      .then((res) => {
        _res(res.data);
      })
      .catch((err) => rej(err));
  });
}

export function retrieveCourseByVendorId(vendorId) {
  return new Promise((_res, rej) => {
    Axios.get(
      BASE_RD_LOCAL_URL + `vendor/retrieve-course-by-vendor-id/${vendorId}`
    )
      .then((res) => {
        _res(res.data);
        // console.log("response" , res.data)
      })
      .catch((err) => rej(err));
  });
}

export function retrieveVendorById(vendorId) {
  return new Promise((_res, rej) => {
    Axios.get(BASE_RD_LOCAL_URL + `vendor/retrieve-vendor-by-id/${vendorId}`)
      .then((res) => {
        _res(res.data);
        // console.log("response" , res.data)
      })
      .catch((err) => rej(err));
  });
}

export function psychometricListForSingleUsers(courseId) {
  return new Promise((_res, rej) => {
    Axios.get(
      BASE_RD_LOCAL_URL + `vendor/retrieve-psychometric-data-by-id/${courseId}`
    )
      .then((res) => {
        _res(res.data);
      })
      .catch((err) => rej(err));
  });
}

export function s3UploadDocs(value) {
  return new Promise((_res, rej) => {
    Axios.post(
      BASE_RD_LOCAL_URL + `s3/upload-docs`, value )
      .then((res) => {
        _res(res.data);
      })
      .catch((err) => rej(err));
  });
}

export function s3GetDocs(tk) {
  let config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: tk,
    },
  };
  return new Promise((_res, rej) => {
    Axios.get(
      BASE_RD_LOCAL_URL + `s3/get-docs`, config )
      .then((res) => {
        _res(res.data);
      })
      .catch((err) => rej(err));
  });
}

export function s3Bucket(value) {
  return new Promise((_res, rej) => {
    Axios.post(
      BASE_RD_LOCAL_URL + `single`, value )
      .then((res) => {
        _res(res.data);
      })
      .catch((err) => rej(err));
  });
}

export function updatePassword(values , email) {
  let body = values
    

  return new Promise((res, rej) => {
    Axios.post(BASE_RD_LOCAL_URL + "user/update-password", body)
      .then((response) => {
        res(response.data);
      })
      .catch((err) => rej(err));
  });
}


export function assignLessonPlan(values , tk) {
  // let body = {
  //   email: values.email,
  //   newPassword: values.newPassword,
  //   passwordConf: values.passwordConf,
  // };

  let body = values

  let config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: tk,
    },
  };

  return new Promise((res, rej) => {
    Axios.post(BASE_RD_LOCAL_URL + "teacher/assign-multi-lesson-plans-to-one-class", body , config)
      .then((response) => {
        res(response.data);
      })
      .catch((err) => rej(err));
  });
}

export function otpVerification(values) {
  let body = values;
  let config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  return new Promise((res, rej) => {
    Axios.post(BASE_RD_LOCAL_URL + "user/verify-email-otp", body, config)
      .then((response) => {
        // ('response ::::::::::', response);
        res(response.data);
      })
      .catch((err) => rej(err));
  });
}

export function bookmarkTopic(token, topicID, removeflag) {
  let body = {
    bookmarkContentId: topicID,
  };
  let config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  };
  return new Promise((res, rej) => {
    Axios.post(
      BASE_RD_LOCAL_URL + `user/bookmark-content?removeFlag=${removeflag}`,
      body,
      config
    )
      .then((response) => {
        res(response.data);
      })
      .catch((err) => {
        rej(err);
        toast.push(
          <Notification type="danger" title={err.response.data.Message} />,
          {
            placement: "top-center",
          }
        );
      });
  });
}

export function retrieveBookmarkContent(tk, contentName, grade, subjectVal, contentType) {
  let config = {
    headers: {
      Authorization: tk,
    },
  };

  return new Promise((_res, rej) => {
    Axios.get(
      BASE_RD_LOCAL_URL +
        `user/get-bookmark-content-with-filter?Name=${subjectVal}&Grade=${grade}&contentname=${contentName}&contentype=${contentType}`,
      config
    )
      .then((res) => {
        _res(res.data);
      })
      .catch((err) => rej(err));
  });
}

export function requestCourse(token, id, removeFlag, course) {
  let body = {
    testId: id,
  };
  let config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  };
  return new Promise((res, rej) => {
    Axios.post(
      BASE_RD_LOCAL_URL +
      `user/create-request?type=${course}&removeFlag=${removeFlag}`,
      body,
      config
    )
      .then((response) => {
        // console.log('response from cvreate profile::::::::::', response);
        res(response.data);
      })
      .catch((err) => {
        rej(err);
        // console.log("rej(err)",err.response.data)
        toast.push(
          <Notification title={err.response.data.Message} type="danger" />,
          {
            placement: "top-center",
          }
        );
      });
  });
}

export function assignRemoveStudentsToClass(value, token) {
  let body = value;
  let config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  };
  return new Promise((res, rej) => {
    Axios.post(
      BASE_RD_LOCAL_URL +
      `teacher/assign-remove-students-to-class?removeFlag=false`,
      body,
      config
    )
      .then((response) => {
        // console.log('response from cvreate profile::::::::::', response);
        res(response.data);
      })
      .catch((err) => {
        rej(err);
        // console.log("rej(err)",err.response.data)
        toast.push(
          <Notification title={err.response.data.Message} type="danger" />,
          {
            placement: "top-center",
          }
        );
      });
  });
}

export function getAssignedStudentsToTeacher(tk, classId , dataId , dataType) {
  
  let config = {
    headers: {
      Authorization: tk,
    },
  };

  return new Promise((_res, rej) => {
    Axios.get(
      BASE_RD_LOCAL_URL +
      `teacher/get-assigned-students-to-teacher/${classId}?type=${dataType}&courseId=${dataType == "course" ? dataId : ""}&quizId=${dataType == "quiz" ? dataId : ""}`,
      config
    )
      .then((res) => {
        _res(res.data);
      })
      .catch((err) => rej(err));
  });
}
export function getTeacherAssignedCourses(tk , classId) {
  let config = {
    headers: {
      Authorization: tk,
    },
  };

  return new Promise((_res, rej) => {
    Axios.get(
      BASE_RD_LOCAL_URL +
        `teacher/get-teacher-assigned-courses?classId=${classId ? classId : ""}`,
      config
    )
      .then((res) => {
        _res(res.data);
      })
      .catch((err) => rej(err));
  });
}
export function getTeacherAssignedCoursesMyCourse(tk) {
  let config = {
    headers: {
      Authorization: tk,
    },
  };

  return new Promise((_res, rej) => {
    Axios.get(
      BASE_RD_LOCAL_URL +
        `teacher/get-teacher-assigned-courses-my-Course`,
      config
    )
      .then((res) => {
        _res(res.data);
      })
      .catch((err) => rej(err));
  });
}

export function getPartnerContent(value , token , type) {
  let body = value
  let config = {
    headers: {
      Authorization: token,
    },
  };
  return new Promise((_res, rej) => {
    Axios.post(BASE_RD_LOCAL_URL + `vendor/get-partner-content/?type=${type}` , body , config)
      .then((res) => {
        _res(res.data);
      })
      .catch((err) => rej(err));
  });
}


export function createQuiz(values, tk) {

  let body = {
    title: values.title,
    topic: values.topic,
    imageUrl: values.imageUrl,
    duration: values.duration,
    description : values.description,
    subjectName : values.subjectName,
    difficultyLevel : values.difficultyLevel,
    questions: values.questions, 
  };

  let config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: tk,
    },
  };
  return new Promise((res, rej) => {
    Axios.post(BASE_RD_LOCAL_URL + "user/create-quiz", body, config)
      .then((response) => {
        // console.log("response from cvreate profile::::::::::", response);
        res(response.data);
      })
      .catch((err) => rej(err));
  });
}
export function updatequestionapi(values) {

  let body = {
    title: values.title,
    topic: values.topic,
    quizId:values._id,
  
    duration: values.duration,
    description : values.description,
    subjectName : values.subjectName,
   
    questions: values.questions, 
  };

  
  return new Promise((res, rej) => {
    Axios.post(BASE_RD_LOCAL_URL + `user/updatequestionapi`, body)
      .then((response) => {
        // console.log("response from cvreate profile::::::::::", response);
        res(response.data);
      })
      .catch((err) => rej(err));
  });
}



export function retieveQuizData(token , titleName , classId) {

  let config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  };
  return new Promise((_res, rej) => {
    Axios.get(BASE_RD_LOCAL_URL + `user/retrieve-all-quiz?&Title=${titleName}&classId=${classId}` , config)
      .then((res) => {
        _res(res.data);
      })
      .catch((err) => rej(err));
  });
}


export function retrieveResourses(tk , type) {
  let config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: tk,
    },
  };
  return new Promise((_res, rej) => {
    Axios.get(BASE_RD_LOCAL_URL + `user/retrieve-resourses?type=${type}` ,config )
      .then((res) => {
        _res(res.data);
      })
      .catch((err) => rej(err));
  });
}

export function getQuizById(quizId) {
  return new Promise((_res, rej) => {
    Axios.get(BASE_RD_LOCAL_URL + `user/get-quiz-by-id/${quizId}`)
      .then((res) => {
        _res(res.data);
      })
      .catch((err) => rej(err));
  });
}


export function getAssignedCoursesOfClass(token , classId) {

  let config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  };

  return new Promise((_res, rej) => {
    Axios.get(BASE_RD_LOCAL_URL + `teacher/get-assigned-courses-of-class/${classId}` , config)
      .then((res) => {
        _res(res.data);
      })
      .catch((err) => rej(err));
  });
}
export function assignCoursesStudent(value,token,key) {
	let body = value ;
  let config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  };

	return new Promise((res, rej) => {
		Axios.post(BASE_RD_LOCAL_URL + `user/assign-remove-course-to-students?removeFlag=${false}&type=${key == "quiz" ? "quiz" : "course"}`, body, config )
			.then((response) => {
				res(response.data);
			})
			.catch((err) => rej(err));
	});
}


export function assignRemoveCourseFromClass(value , token) {
	let body = value ;
  let config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  };

	return new Promise((res, rej) => {
		Axios.post(BASE_RD_LOCAL_URL + `teacher/assign-remove-course-from-class?removeFlag=${false}`, body, config )
			.then((response) => {
				res(response.data);
			})
			.catch((err) => rej(err));
	});
}

export function addTeacherCourses(value , token) {
	let body = value;
  let config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  };

	return new Promise((res, rej) => {
		Axios.post(BASE_RD_LOCAL_URL + `teacher/add-teacher-courses`, body, config )
			.then((response) => {
				res(response.data);
			})
			.catch((err) => rej(err));
	});
}



export function createResources(values, token) {
 console.log("values",values,token)
  let body = values

  let config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  }



  return new Promise((res, rej) => {
    Axios.post(BASE_RD_LOCAL_URL + "user/create-resourses", body, config)
      .then((response) => {
        res(response.data);
      })
      .catch((err) => rej(err));
  });
}


export function retreiveResources(type, token) {

  let config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  };

  return new Promise((_res, rej) => {
    Axios.get(BASE_RD_LOCAL_URL + `user/retrieve-resourses?type=${type}`, config)
      .then((res) => {
        _res(res.data);
      })
      .catch((err) => rej(err));
  });
}

export function getAssignLessonPlan(token , classId) {

  let config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  };

  return new Promise((_res, rej) => {
    Axios.get(BASE_RD_LOCAL_URL + `teacher/get-assign-lesson-plan/${classId}`, config)
      .then((res) => {
        _res(res.data);
      })
      .catch((err) => rej(err));
  });
}

export function deleteLessonPlanner(lessonPlanerId) {
return new Promise((_res, rej) => {
    Axios.delete(BASE_RD_LOCAL_URL + `teacher/delete-lesson-planner/${lessonPlanerId}`)
      .then((res) => {
        _res(res.data);
      })
      .catch((err) => rej(err));
  });
}

export function deleteQuiz(quizId) {
return new Promise((_res, rej) => {
    Axios.delete(BASE_RD_LOCAL_URL + `user/deletequizapi/${quizId}`)
      .then((res) => {
        _res(res.data);
      })
      .catch((err) => rej(err));
  });
}


export function assignOneQuizToMultiClass(values,token) {
  console.log("values,token",values,token)
  let body = values
  let config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  };
    return new Promise((res, rej) => {
    Axios.post(BASE_RD_LOCAL_URL + "user/assign-one-quiz-to-multi-class", body,config)
      .then((response) => {
        res(response.data);
      })
      .catch((err) => rej(err));
  });
}

export function getAssignQuizToClass(classId) {
  return new Promise((_res, rej) => {
    Axios.get(BASE_RD_LOCAL_URL + `user/get-assign-quiz-to-class/${classId}`)
      .then((res) => {
        _res(res.data);
      })
      .catch((err) => rej(err));
  });
}

export function getQuizTakenStatsForClass(token ,quizId , classId) {

  let config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  };

  return new Promise((_res, rej) => {
    Axios.get(BASE_RD_LOCAL_URL + `teacher/get-quiz-taken-stats-for-class/${quizId}/${classId}`, config)
      .then((res) => {
        _res(res.data);
      })
      .catch((err) => rej(err));
  });
}

export function getQuizTakenStudentsForClass(token ,quizId , classId) {

  let config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  };

  return new Promise((_res, rej) => {
    Axios.get(BASE_RD_LOCAL_URL + `teacher/get-quiz-taken-students-for-class/${quizId}/${classId}`, config)
      .then((res) => {
        _res(res.data);
      })
      .catch((err) => rej(err));
  });
}
export function getQuizAnalytics(token ,quizId , classId) {

  let config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  };

  return new Promise((_res, rej) => {
    Axios.get(BASE_RD_LOCAL_URL + `teacher/get-quiz-analytics/${quizId}/${classId}`, config)
      .then((res) => {
        _res(res.data);
      })
      .catch((err) => rej(err));
  });
}

export function coursesUsesByClass(token) {

  let config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  };

  return new Promise((_res, rej) => {
    Axios.get(BASE_RD_LOCAL_URL + `teacher/courses-uses-by-class`, config)
      .then((res) => {
        _res(res.data);
      })
      .catch((err) => rej(err));
  });
}
export function quizReportDetailsOfOneStudent(quizId , studentUserId) {

  

  return new Promise((_res, rej) => {
    Axios.get(BASE_RD_LOCAL_URL + `teacher/quiz-report-details-of-one-student/${quizId}/${studentUserId}`)
      .then((res) => {
        _res(res.data);
      })
      .catch((err) => rej(err));
  });
}
