import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  vendorProfileData: null,
  indexForUrl: 0,
  authToken: null,
  teacherDataComingFromLogin:null,
  oneCourseDetail:null,
  courseDataForRequest:null,
  openDialogForRequest:false,
  openDialogForAssign:false,
  checkIndexForShelf: null,
  addDetailsWarning: false,
  isTeacherOnBoard: false,
  skipOnboard:false,
  onBoardingdata: null,
  openDialogOfUnsub: false,
  quizData: null,
  courseLeftLisences:null,
  lessonApi : false
  
};

export const vendorSlice = createSlice({
  name: "vendor",
  initialState,
  reducers: {
    setVendorProfileData: (state, action) => {
      state.vendorProfileData = action.payload;
    },
    setTeacherDataComingFromLogin: (state, action) => {
	state.teacherDataComingFromLogin = action.payload;
           },
    setIndexForUrl: (state, action) => {
      state.indexForUrl = action.payload;
    },
    setAuthToken: (state, action) => {
      state.authToken = action.payload;
    },
    setOneCourseDetail:(state,action) => {
      state.oneCourseDetail = action.payload;
    },
    setCourseDataForRequest: (state, action) => {
      state.courseDataForRequest = action.payload;
    },
    setOpenDialogForRequest:(state,action) =>{
      state.openDialogForRequest = action.payload
    },
    setOpenDialogForAssign : (state,action) =>{
      state.openDialogForAssign = action.payload
    },
    setCheckIndexForShelf:(state,action) =>{
      state.checkIndexForShelf = action.payload
    },
    setAddDetailsWarning:(state, action) => {
      state.addDetailsWarning = action.payload;
    },
    setIsTeacherOnBoard: (state, action) => {
      state.isTeacherOnBoard = action.payload;
    },
    setSkipOnBoard: (state, action) => {
      state.skipOnboard = action.payload;
    },
    setOnBoardingdata: (state, action) => {
      state.skipOnboard = action.payload;
    },
    setOpenDialogOfUnsub: (state, action) => {
      state.skipOnboard = action.payload;
    },
    setQuizData: (state, action) => {
      state.quizData = action.payload;
    },
    setCourseLeftLisences: (state, action) => {
      state.courseLeftLisences = action.payload;
    },
    setLessonApi: (state, action) => {
      state.lessonApi = action.payload;
    }
  
    
  },  
});

export const {
  setVendorProfileData,
  setIndexForUrl,
  setAuthToken,
  setTeacherDataComingFromLogin,
  setOneCourseDetail,
  setCourseDataForRequest,
  setOpenDialogForRequest,
  setopenDialogForAssign,
  setCheckIndexForShelf,
  setAddDetailsWarning,
  setIsTeacherOnBoard,
  setSkipOnBoard,
  setOnBoardingdata,
  setOpenDialogOfUnsub,
  setQuizData,
  setCourseLeftLisences,
  setLessonApi
} = vendorSlice.actions;

export default vendorSlice.reducer;
