import { useSelector, useDispatch } from "react-redux";
import { setUser, initialState } from "store/auth/userSlice";
import { apiSignIn, apiSignOut, companySignInData } from "services/AuthService";
import { onSignInSuccess, onSignOutSuccess } from "store/auth/sessionSlice";
import appConfig from "configs/app.config";
import { REDIRECT_URL_KEY } from "constants/app.constant";
import { useNavigate } from "react-router-dom";
import { setAddDetailsWarning, setCourseDataForRequest, setTeacherDataComingFromLogin, setProfileUserID } from "store/vendor/vendorSlice";
import useQuery from "./useQuery";
import { signInUserData } from "mock/data/authData";
import {getAllCoursesListForUsers, getUserProfileBasedOnRole} from "services/LiveApi"
import { login } from "services/LiveApi";
import jwt from "jwt-decode";
import { useEffect } from "react";
import { useState } from "react";
import { Notification, toast } from "components/ui";
import { error } from "jquery";


function useAuth() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const query = useQuery();

  const {isProfileCompleted} = useSelector((state) => state.auth.user);

  const { token, signedIn } = useSelector((state) => state.auth.session);

  const [inData , setInData] = useState(null);





  // useEffect(() => {
  //   getUserProfileBasedOnRoles(tok.token);
  // }, []);


 
 
 
  const getUserProfileBasedOnRoles = async (token) => {
    let teacherContent = await getUserProfileBasedOnRole(token); 
    // setInData(teacherContent.response);
    console.log("teacherContentteacherContentteacherContent", teacherContent)
    dispatch(setTeacherDataComingFromLogin(teacherContent.response))
  };



  const courseDataFromApi = async()=>{
    const courseData = await getAllCoursesListForUsers()
    dispatch(setCourseDataForRequest(courseData.response))
    // setDataSetFromApi(courseData.response)
  }


  const signIn = async (userName, password) => {
    let profileResponse 
     const resp = await login(userName, password);
      console.log("resp:::::::::::", resp);
      if (resp.Success ) {       
         profileResponse =  jwt(resp.Body); 
        if (profileResponse.role == "Teacher" || profileResponse.role == "teacher"  ){
          const token = resp.Body
     
          if(profileResponse.isProfileCompleted && token)
          {
            profileResponse.isProfileCompleted && 
             token && await getUserProfileBasedOnRoles(token)
             await courseDataFromApi()
          }
          // token && await courseDataFromApi()
          
          console.log("profileResponseprofileResponseprofileResponse", profileResponse)
          

            dispatch(onSignInSuccess(token));
            dispatch(
              setUser({
                // avatar: signInUserData[0].avatar,
                // userName: profileResponse.name,
                // authority: signInUserData[0].authority,
                email: profileResponse.email,
                isProfileCompleted:profileResponse.isProfileCompleted,
                // role: profileResponse.data.Response.role,
                profileuserID: profileResponse.user_id,
              })
              );
              navigate("/app/teacher/dashboard")
        } else{
          toast.push( 
            <Notification
              title={`This Email Is not registered!`}
              type="danger"
            />,
            {
              placement: "top-end",
            }
          );
        }
      }else{
      toast.push( 
        <Notification
          title={`${resp.Message}`}
          type="danger"
        />,
        {
          placement: "top-end",
        }
      );
    }
  };



  const handleSignOut = () => {
    dispatch(onSignOutSuccess());
    dispatch(setUser(initialState));
    navigate(appConfig.unAuthenticatedEntryPath);
    dispatch(setAddDetailsWarning(false))
  };

  const signOut = async () => {
    try {
      await apiSignOut();
      handleSignOut();
    } catch (errors) {
      handleSignOut();
    }
  };

  return {
    authenticated: token && signedIn,
    signIn,
    signOut,
  };
}

export default useAuth;
